"use client";

import { useEffect } from "react";
import { usePathname, useSearchParams } from "next/navigation";

import { initPostHog } from "@acme/analytics";

interface PostHogProviderProps {
  children: React.ReactNode;
}

export function PostHogProvider({ children }: PostHogProviderProps) {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    // Initialize PostHog
    initPostHog();
  }, []);

  // Track page changes
  useEffect(() => {
    if (pathname) {
      // Construct the URL with search params
      const url = searchParams?.size
        ? `${pathname}?${searchParams.toString()}`
        : pathname;

      // We use a small delay to ensure the page has fully loaded
      const timeout = setTimeout(() => {
        window.posthog?.capture("$pageview", {
          $current_url: url,
        });
      }, 300);

      return () => clearTimeout(timeout);
    }
  }, [pathname, searchParams]);

  return <>{children}</>;
}
