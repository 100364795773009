import posthog from 'posthog-js';
import { env } from './env.mjs';

/**
 * PostHog event properties interface
 */
export interface EventProperties {
	[key: string]: string | number | boolean | null | undefined;
}

/**
 * Initialize PostHog with the provided configuration
 */
export function initPostHog() {
	// Only initialize in browser environment
	if (typeof window !== 'undefined') {
		posthog.init(env.NEXT_PUBLIC_POSTHOG_KEY, {
			api_host: env.NEXT_PUBLIC_POSTHOG_HOST,
			capture_pageview: true, // Automatically capture pageviews
			capture_pageleave: true, // Capture when users leave the page
			autocapture: true, // Automatically capture clicks, form submissions etc.
			persistence: 'localStorage',
			loaded: (posthog) => {
				if (process.env.NODE_ENV === 'development') {
					// Make available during development
					window.posthog = posthog;
				}
			},
		});
	}
}

/**
 * Track a custom event with optional properties
 */
export function trackEvent(eventName: string, properties?: EventProperties) {
	if (typeof window !== 'undefined') {
		posthog.capture(eventName, properties);
	}
}

/**
 * Identify a user with their ID and optional properties
 */
export function identifyUser(
	userId: string,
	properties?: {
		name?: string;
		email?: string;
		[key: string]: any;
	}
) {
	if (typeof window !== 'undefined') {
		posthog.identify(userId, properties);
	}
}

/**
 * Reset the current user's identity
 */
export function resetUser() {
	if (typeof window !== 'undefined') {
		posthog.reset();
	}
}

/**
 * Set properties for the current user
 */
export function setUserProperties(properties: Record<string, any>) {
	if (typeof window !== 'undefined') {
		posthog.people.set(properties);
	}
}

/**
 * Track a page view manually (useful for SPAs)
 */
export function trackPageView(url?: string) {
	if (typeof window !== 'undefined') {
		posthog.capture('$pageview', {
			$current_url: url || window.location.href,
		});
	}
}

/**
 * Enable or disable session recording
 */
export function setSessionRecording(enabled: boolean) {
	if (typeof window !== 'undefined') {
		if (enabled) {
			posthog.startSessionRecording();
		} else {
			posthog.stopSessionRecording();
		}
	}
}

// Export the raw posthog instance for advanced usage
export { posthog };

// Types for global window object in development
declare global {
	interface Window {
		posthog?: typeof posthog;
	}
}
